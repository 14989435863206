import React from "react"
import logo from "../../../assets/images/logo.jpg"
import { useMatch } from "react-router-dom"

import styles from "./AppHeader.module.scss"
import AppMobileMenu from "./AppMobileMenu"
import Button from "../Content/Button"

function AppHeader() {
  const match = useMatch("/")

  return (
    <header className={styles.header} id="top">
      <img
        title="Service-Meister"
        alt="Service-Meister"
        className={styles.logo}
        src={logo}
        width="200"
        height="65"
      />
      {!match ? (
        <Button to={"/"} className={styles.home} ghost>
          Zur Einführung
        </Button>
      ) : null}

      <AppMobileMenu className={styles.mobileMenu} />
    </header>
  )
}

export default AppHeader
