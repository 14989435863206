import React, { useEffect } from "react"
import PageIntro from "../../Elements/Content/PageIntro"
import ProcessNavigation from "../../Elements/Navigation/ProcessNavigation"
import { useAppDispatch, useAppSelector } from "../../../state/store"
import ReportChartDebugger from "./ReportChartDebugger"
import styles from "./Report.module.scss"
import Tile, { TileType } from "../../Elements/Content/Tile"
import ReportChart from "./ReportChart"
import { progressSelector, reportsSelector, surveySelector } from "../../../state/selectors"
import ReportChartLegend from "./ReportChartLegend"
import VideoTeaser from "../../Elements/Content/VideoTeaser"
import { setLastTabKey } from "../../../state/catalogSlice"

function Report() {
  const survey = useAppSelector(surveySelector)
  const reports = useAppSelector(reportsSelector)
  const progress = useAppSelector(progressSelector)
  const dispatch = useAppDispatch()

  const lastSurveyPage = `/analyse/${survey[survey.length - 1]?.slug ?? ""}`
  const navigation = <ProcessNavigation toNext={"/katalog"} toPrevious={lastSurveyPage} />

  useEffect(() => {
    dispatch(setLastTabKey(null))
  })

  return (
    <>
      <section className={styles.report}>
        <PageIntro title={`Welche KI-Anwendungen sind für Sie interessant? `}>
          <p>
            Wie KI im Technischen Service unterstützen kann, bringt Ihnen der von Service-Meister
            entwickelte Avatar „Gloria“ im Video näher. Durch ihn erfahren Sie anschaulich und
            Schritt für Schritt am Servicelifecycle orientiert, wie ein KI-basiertes Ökosystem ins
            Unternehmen eingeführt wird und wie sich firmeninternes Servicewissen besser nutzen und
            vermarkten lässt.
          </p>
          <VideoTeaser embedId="N-WNHjihPpk" />
        </PageIntro>
        <Tile
          className={styles.navigation + (progress.total < 100 ? " " + styles.notice : "")}
          type={progress.total < 100 ? TileType.NOTICE : TileType.DEFAULT}
        >
          {progress.total < 100 ? (
            <>
              <p>
                Ihre Angaben sind unvollständig. Um genauere Ergbenisse zu erhalten, können Sie
                versuchen, alle Fragen zu beantworten.
              </p>
              <p>Andernfalls können sie direkt weiter zum Katalog gehen.</p>
            </>
          ) : null}
          {navigation}
        </Tile>
        <div className={styles.chart}>
          <ReportChart reports={reports} />
          <ReportChartLegend />
        </div>
      </section>

      <ReportChartDebugger />
    </>
  )
}

export default Report
