import { RootState } from "./store"
import { createSelector } from "@reduxjs/toolkit"
import { IMainCategory } from "../interfaces/IMainCategory"

export const rootSelector = (state: RootState) => state
export const surveySelector = createSelector(
  [rootSelector],
  (state: RootState) => state.analysis.survey
)
export const answersSelector = createSelector(
  [rootSelector],
  (state: RootState) => state.analysis.answers
)
export const progressSelector = createSelector(
  [rootSelector],
  (state: RootState) => state.analysis.progress
)
export const createProgressSelector = (categoryId?: string) =>
  createSelector([progressSelector], (progress) => {
    if (categoryId) {
      return progress.byCategory[categoryId] ?? 0
    }

    const percentValues = Object.values(progress.byCategory)
    const percent = percentValues.length
      ? percentValues.reduce((a, b) => a + b, 0) / percentValues.length
      : 0

    return Math.ceil(percent)
  })

export const reportsSelector = createSelector(
  [rootSelector],
  (state: RootState) => state.analysis.reports
)
export const reportByCategoryModuleSelector = (module: number) =>
  createSelector([surveySelector, reportsSelector], (survey, reports) => {
    const category = survey.find((c) => c.module === module)
    return category ? reports[category.id] : null
  })

export const catalogSelector = createSelector([rootSelector], (state: RootState) => state.catalog)
export const enteredOnDetailViewSelector = createSelector(
  [catalogSelector],
  (catalog) => catalog.enteredOnDetailView
)
export const catalogServicesSelector = createSelector(
  [catalogSelector],
  (catalog) => catalog.services
)
export const catalogServiceBySlugSelector = (slug: string) =>
  createSelector([catalogServicesSelector], (services) =>
    services.find((service) => service.slug === slug)
  )
export const currentServiceSelector = createSelector(
  [catalogSelector],
  (catalog) => catalog.currentService
)
export const createServiceByCategorySelector = (category: IMainCategory) =>
  createSelector([catalogServicesSelector], (services) =>
    services.filter((document) => document.module === category.module)
  )
export const createServiceBySlugSelector = (slug: string) =>
  createSelector([catalogServicesSelector], (services) =>
    services.find((document) => document.slug === slug)
  )
export const lastTabKeySelector = createSelector([catalogSelector], (catalog) => catalog.lastTabKey)

export const consentSelector = createSelector([rootSelector], (state: RootState) => state.consent)
export const allowYouTubeVideoSelector = createSelector(
  [consentSelector],
  (consent) => consent.youtubeVideo
)
