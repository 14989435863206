import React from "react"
import { AnalysisCategoryChild } from "../../../interfaces/IMainCategory"
import AnalysisSubCategory from "./AnalysisSubCategory/AnalysisSubCategory"
import AnalysisStatement from "./AnalysisStatement/AnalysisStatement"

type AnalysisChildrenProps = {
  categoryChildren: AnalysisCategoryChild[]
}

function AnalysisChildren({ categoryChildren }: AnalysisChildrenProps) {
  return (
    <>
      {categoryChildren.map((child) => {
        switch (child.type) {
          case "category":
            return <AnalysisSubCategory key={child.id} category={child} />
          case "statement":
            return <AnalysisStatement key={child.id} statement={child} />
        }
        console.error("Unknown child type")
        return <div></div>
      })}
    </>
  )
}

export default AnalysisChildren
