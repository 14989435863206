import { createBrowserRouter } from "react-router-dom"
import Layout from "./components/Layout"
import Introduction from "./components/Pages/Introduction"
import Analysis from "./components/Pages/Analysis/Analysis"
import Report from "./components/Pages/Report/Report"
import Catalog from "./components/Pages/Catalog/Catalog"
import Error from "./components/Pages/Error"
import AnalysisStart from "./components/Pages/Analysis/AnalysisStart"
import ServiceCatalogDetail from "./components/Pages/Catalog/ServiceCatalogDetail"
import AnalysisMainCategory from "./components/Pages/Analysis/AnalysisMainCategory/AnalysisMainCategory"

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Introduction />,
      },
      {
        path: "/analyse",
        element: <Analysis />,
        errorElement: <Error />,
        children: [
          {
            path: "",
            element: <AnalysisStart />,
          },
          {
            path: ":slug",
            element: <AnalysisMainCategory />,
          },
        ],
      },
      {
        path: "/report",
        element: <Report />,
      },
      {
        path: "/katalog",
        element: <Catalog />,
      },
      {
        path: "/katalog/:slug",
        element: <ServiceCatalogDetail />,
      },
    ],
  },
])
