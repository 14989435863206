import React from "react"
import styles from "./StepNumber.module.scss"
import conditionalClassName from "../../../utility/conditionalClassName"

function StepNumber({ number, isActive }: { number: number; isActive: boolean }) {
  return (
    <span
      className={styles.stepNumber + conditionalClassName(styles.active, isActive)}
      title={"" + number}
    >
      {number}
    </span>
  )
}

export default StepNumber
