import React from "react"
import styles from "./AnalysisStatementHeader.module.scss"
import conditionalClassName from "../../../../utility/conditionalClassName"

function AnalysisStatementHeader({ title }: { title?: string }) {
  return (
    <header className={styles.header + conditionalClassName(styles.noTitle, !title)}>
      {title ? <h2>{title}</h2> : <div></div>}
      <div className={styles.actualTarget}>Ist / Soll</div>
      <div className={styles.priority}>Wichtigkeit</div>
    </header>
  )
}

export default AnalysisStatementHeader
