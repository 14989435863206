import React from "react"
import styles from "./ImageTeaser.module.scss"
import Tile, { ClassNames } from "./Tile"

type ImageTeaserProps = {
  image: string
  alt: string
  children?: React.ReactNode
  action?: React.ReactNode
  className?: string | ClassNames
}

function ImageTeaser({ image, alt, children, action, className }: ImageTeaserProps) {
  if (typeof className === "string") {
    className += ` ${styles.imageTeaser}`
  } else if (typeof className === "object") {
    className.tile += ` ${styles.imageTeaser}`
  } else {
    className = styles.imageTeaser
  }

  return (
    <Tile
      className={className}
      header={<img src={image} alt={alt} />}
      headerBleed={true}
      action={action}
    >
      {children}
    </Tile>
  )
}

export default ImageTeaser
