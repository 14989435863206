import { AnalysisCategoryChild, IMainCategory } from "../interfaces/IMainCategory"
import { IAnalysisStatement } from "../interfaces/IAnalysisStatement"
import { AnalysisAnswerState, IAnalysisAnswer } from "../interfaces/IAnalysisAnswer"
import ISurveyReport, { ReportResult } from "../interfaces/ISurveyReport"

export default class AnalyzerService {
  public static thresholdDistribution = new Map([
    [ReportResult.LOW, 0],
    [ReportResult.MEDIUM, 0.51],
    [ReportResult.HIGH, 0.91],
  ])

  static findAllStatements(child: AnalysisCategoryChild | IMainCategory): IAnalysisStatement[] {
    if (child.type === "statement") {
      return [child]
    } else {
      return child.children.flatMap(this.findAllStatements.bind(this))
    }
  }

  static calculateProgress(category: IMainCategory, answers: IAnalysisAnswer[]): number {
    const allStatements = this.findAllStatements(category)
    const allAnswers = this.findAllAnswers(allStatements, answers)
    return Math.ceil((allAnswers.length / allStatements.length) * 100)
  }

  static findAllAnswers(
    statements: IAnalysisStatement[],
    answers: IAnalysisAnswer[]
  ): IAnalysisAnswer[] {
    return statements
      .map((statement) => answers.find((answer) => answer.statement.id === statement.id))
      .filter(
        (answer) => answer !== undefined && answer.state !== AnalysisAnswerState.UNKNOWN
      ) as IAnalysisAnswer[]
  }

  static createReport(category: IMainCategory, answers: IAnalysisAnswer[]): ISurveyReport {
    const allStatements = this.findAllStatements(category)
    const allAnswers = this.findAllAnswers(allStatements, answers)
    const actual = this.calculateScore(allAnswers, AnalysisAnswerState.ACTUAL)
    const target = this.calculateScore(allAnswers, AnalysisAnswerState.TARGET)
    // const maxScore = this.calculateScoreCeiling(allStatements);
    const maxScore = this.calculateWeightedMaxScore(allAnswers)
    const score = actual !== null && target !== null ? actual - target : null

    return {
      category,
      total: {
        actual,
        target,
      },
      score,
      maxScore,
      evaluation: this.evaluateReport(score, maxScore),
    }
  }

  static calculateScore(answers: IAnalysisAnswer[], state: AnalysisAnswerState): number | null {
    if (answers.length === 0) {
      return null
    }

    const score = answers
      .filter((answer) => answer.state === state)
      .map((answer) => answer.priority * answer.statement.weight)
      .reduce((a: number, b: number) => a + b, 0)

    return Math.ceil(score / answers.length)
  }

  // todo: remove when decision process about report calculation is finished
  static calculateTotalMaxScore(statements: IAnalysisStatement[]): number {
    return (
      statements
        .map((statement) => statement.weight * 3)
        .reduce((a: number, b: number) => a + b, 0) / statements.length
    )
  }

  static calculateWeightedMaxScore(answers: IAnalysisAnswer[]): number | null {
    if (answers.length === 0) {
      return null
    }

    const maxScore =
      answers
        .map((answer) => answer.statement.weight * answer.priority)
        .reduce((a: number, b: number) => a + b, 0) / answers.length

    return Math.ceil(maxScore)
  }

  static evaluateReport(
    score: number | null,
    maxScore: number | null
  ): { steps: number[]; result: ReportResult } {
    if (score === null || maxScore === null) {
      return { steps: [], result: ReportResult.MISSING_DATA }
    }

    const absMaxScore = maxScore * 2
    const steps = Array.from(this.thresholdDistribution.values()).map((threshold) =>
      Math.ceil(absMaxScore * threshold)
    )

    // we push the score into the same positive range as the ceiling
    const absScore = score + maxScore

    let result = ReportResult.MISSING_DATA

    if (absScore >= steps[2]) {
      result = ReportResult.HIGH
    } else if (absScore >= steps[1]) {
      result = ReportResult.MEDIUM
    } else if (absScore >= steps[0]) {
      result = ReportResult.LOW
    }

    return { steps, result }
  }
}
