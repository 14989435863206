import React, { forwardRef } from "react"
import styles from "./TableOfContents.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight"
import Tile from "../../Elements/Content/Tile"
import Button from "../../Elements/Content/Button"
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp"

const TableOfContents = forwardRef(function TableOfContents(
  {
    entries,
  }: {
    entries: [string, string][]
  },
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <Tile
      id="toc"
      className={styles.tableOfContents}
      header={<h3 className={styles.title}>Inhalt</h3>}
      ref={ref}
    >
      <ul>
        {entries.map(([key, title]) => (
          <li key={key}>
            <a href={`#${key}`}>
              <FontAwesomeIcon icon={faChevronRight} />
              {title}
            </a>
          </li>
        ))}
      </ul>
      <Button href="#top" className={styles.toTop} small>
        <FontAwesomeIcon icon={faChevronUp} />
        <span>Nach Oben</span>
      </Button>

      <Button href="#toc" className={styles.toTopMobile} small>
        <FontAwesomeIcon icon={faChevronUp} />
        <span>Nach Oben</span>
      </Button>
    </Tile>
  )
})

export default TableOfContents
