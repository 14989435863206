import React from "react"
import styles from "./Table.module.scss"

type ColumnKey = string
type RowKey = string
type Rows = Map<ColumnKey, Map<RowKey, string>>
type RawCell = Record<RowKey, string>
type RawRows = Record<ColumnKey, RawCell>

type TableProps = {
  columnHeaders: Map<ColumnKey, string>
  rowHeaders: Map<RowKey, string>
  rawRows: RawRows
}

type RenderTableProps = {
  columnHeaders: Map<ColumnKey, string>
  rowHeaders: Map<RowKey, string>
  rows: Rows
}

function Table({ columnHeaders, rowHeaders, rawRows }: TableProps) {
  const rows = convertRowsToMap(rawRows)

  return (
    <div className={styles.tableWrapper}>
      <DesktopTable columnHeaders={columnHeaders} rowHeaders={rowHeaders} rows={rows} />
      <MobileTable columnHeaders={columnHeaders} rowHeaders={rowHeaders} rows={rows} />
    </div>
  )
}

export default Table

function convertRowsToMap(rows: RawRows): Map<ColumnKey, Map<RowKey, string>> {
  const rowsMapArray: [ColumnKey, Map<RowKey, string>][] = Object.entries(rows).map(
    ([colKey, cell]) => [colKey as ColumnKey, new Map(Object.entries(cell))]
  )
  return new Map(rowsMapArray)
}

function DesktopTable({ columnHeaders, rowHeaders, rows }: RenderTableProps) {
  return (
    <table className={styles.desktopTable}>
      <thead>
        <tr>
          <th></th>
          {Array.from(columnHeaders.entries()).map(([colKey, title]) => (
            <th key={colKey}>
              <div>{title}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from(rowHeaders.entries()).map(([rowKey, title]) => (
          <tr key={rowKey}>
            <th key={rowKey + "_title"}>
              <div>{title}</div>
            </th>
            {Array.from(columnHeaders.keys()).map((colKey) => {
              const content = rows.get(colKey)?.get(rowKey) ?? ""
              return <td key={rowKey + "_" + colKey}>{content}</td>
            })}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

function MobileTable({ columnHeaders, rowHeaders, rows }: RenderTableProps) {
  return (
    <div className={styles.mobileTable}>
      {Array.from(rows.entries()).map(([colKey, row]) => (
        <details key={colKey}>
          <summary>{columnHeaders.get(colKey)}</summary>
          <div>
            <dl>
              {Array.from(row.entries()).map(([rowKey, content], index) => (
                <div
                  key={index}
                  className={"appear"}
                  style={{ ["--appear-order" as string]: index + 1 }}
                >
                  <dt>{rowHeaders.get(rowKey)}</dt>
                  <dd>{content}</dd>
                </div>
              ))}
            </dl>
          </div>
        </details>
      ))}
    </div>
  )
}
