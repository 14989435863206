import React from "react"
import { useDispatch } from "react-redux"
import { RouterProvider } from "react-router-dom"
import { routes } from "../routes"
import { initSurvey } from "../state/analysisSlice"
import analysisJson from "../assets/analysis.json"
import catalogJson from "../assets/catalog.json"
import { IAnalysisData } from "../interfaces/IAnalysisData"
import { AnalysisCategoryChild, IMainCategory } from "../interfaces/IMainCategory"
import uniqueId from "lodash/uniqueId"
import { slugify } from "./Pages/Analysis/slugify"
import { initCatalog } from "../state/catalogSlice"
import ICatalogService from "../interfaces/ICatalogService"

function App() {
  const dispatch = useDispatch()
  dispatch(initSurvey(convert(analysisJson as IAnalysisData)))
  dispatch(initCatalog(catalogJson as ICatalogService[]))

  return <RouterProvider router={routes} />
}

export default App

function convert(json: IAnalysisData): IMainCategory[] {
  // create slugs for main categories
  return json.survey.map((category) => ({
    ...category,
    id: category.id ?? createId(),
    slug: slugify(category.navigationTitle ?? category.title),
    children: category.children.map(decorate),
  }))
}

function decorate(child: AnalysisCategoryChild): AnalysisCategoryChild {
  return {
    ...child,
    id: child.id ?? createId(),
    ...(child.type === "category" && {
      children: child.children.map(decorate),
    }),
  }
}

function createId() {
  return uniqueId("analysis-child-")
}
