import { IAnalysisStatement } from "./IAnalysisStatement"

export enum AnalysisAnswerState {
  UNKNOWN,
  ACTUAL,
  TARGET,
}

export interface IAnalysisAnswer {
  statement: IAnalysisStatement
  state: AnalysisAnswerState
  priority: number
}

export interface IAnalysisAnswerDto extends Omit<IAnalysisAnswer, "state" | "priority"> {
  state?: AnalysisAnswerState
  priority?: number
}
