import React from "react"
import styles from "./AnalysisHeader.module.scss"
import AnalysisTabs from "../AnalysisTabs/AnalysisTabs"

function AnalysisHeader() {
  return (
    <header className={styles["analysis-header"]}>
      <AnalysisTabs />
    </header>
  )
}

export default AnalysisHeader
