import React, { useState } from "react"
import styles from "./RangeSlider.module.scss"
import {
  GetHandleProps,
  GetRailProps,
  GetTrackProps,
  Handles,
  Rail,
  Slider,
  SliderItem,
  Ticks,
  Tracks,
} from "react-compound-slider"

type RangeSliderProps = {
  min: number
  max: number
  step: number
  defaultValue: number
  onChange: (values: ReadonlyArray<number>) => void
  className?: string
}

const sliderStyle: React.CSSProperties = {
  position: "relative",
  width: "100%",
}

function RangeSlider({ min, max, step, defaultValue, onChange, className }: RangeSliderProps) {
  const values = [defaultValue ?? 0]
  const domain = [min, max]

  return (
    <div className={styles.rangeSlider + (className ? " " + className : "")}>
      <Slider
        mode={1}
        step={step}
        domain={domain}
        rootStyle={sliderStyle}
        onChange={onChange}
        values={values}
      >
        <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={4}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((tick) => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  )
}

export default RangeSlider

type SliderRailProps = {
  getRailProps: GetRailProps
}

function SliderRail({ getRailProps }: SliderRailProps) {
  return (
    <>
      <div className={styles["rail-outside"]} {...getRailProps()} />
      <div className={styles["rail-inside"]} />
    </>
  )
}

type TrackProps = {
  source: SliderItem
  target: SliderItem
  getTrackProps: GetTrackProps
  disabled?: boolean
}

function Track({ source, target, getTrackProps, disabled = false }: TrackProps) {
  return (
    <div
      className={styles.track}
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  )
}

type HandleProps = {
  domain: number[]
  handle: SliderItem
  getHandleProps: GetHandleProps
  disabled?: boolean
}

function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled = false,
  getHandleProps,
}: HandleProps) {
  const [hover, setHover] = useState(false)

  const onMouseEnter = () => {
    setHover(true)
  }

  const onMouseLeave = () => {
    setHover(false)
  }

  return (
    <>
      <div
        className={styles.handle}
        style={{
          left: `${percent}%`,
        }}
        {...getHandleProps(id, { onMouseEnter, onMouseLeave })}
      />
      <div
        className={
          styles["handle-slider"] +
          (disabled ? " " + styles.disabled : "") +
          (hover ? " " + styles.hover : "")
        }
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
        }}
      />
    </>
  )
}

// *******************************************************
// TICK COMPONENT
// *******************************************************
interface TickProps {
  tick: SliderItem
  count: number
  format?: (val: number) => string
}

export const Tick: React.FC<TickProps> = ({ tick, count, format = (d) => d }) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: 14,
          width: 1,
          height: 5,
          backgroundColor: "rgb(200,200,200)",
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: "absolute",
          marginTop: 22,
          fontSize: 10,
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  )
}
