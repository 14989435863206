import React from "react"
import styles from "./ReportFlower.module.scss"
import { ReportResult } from "../../../interfaces/ISurveyReport"
import { ReactComponent as Flower } from "../../../assets/images/report-flower.svg"
import conditionalClassName from "../../../utility/conditionalClassName"

type ReportFlowerProps = {
  module: number
  result: ReportResult
  className?: string
}

function ReportFlower({ module, result, className }: ReportFlowerProps) {
  return (
    <div className={styles.reportFlower + conditionalClassName(className)} data-module={module}>
      <Flower
        style={{
          [`--result-modul-${module}` as string]: `var(--result-color-${result})`,
        }}
      />
    </div>
  )
}

export default ReportFlower
