import React, { useEffect } from "react"
import styles from "./Video.module.scss"
import conditionalClassName from "../../../utility/conditionalClassName"
import Button from "./Button"
import youtubePreviewUrl from "../../../utility/youtubePreviewUrl"
import { useAppDispatch, useAppSelector } from "../../../state/store"
import { allowYouTubeVideoSelector } from "../../../state/selectors"
import { allowYouTubeVideo } from "../../../state/consentSlice"

function Video({ embedId }: { embedId: string }) {
  const allowYouTube = useAppSelector(allowYouTubeVideoSelector)
  const dispatch = useAppDispatch()
  const [allowVideo, setAllowVideo] = React.useState(allowYouTube)

  const alwaysAllow = () => {
    setAllowVideo(true)
    dispatch(allowYouTubeVideo())
  }

  useEffect(() => {
    setAllowVideo(allowYouTube)
  }, [allowYouTube])

  return (
    <div className={styles.video + conditionalClassName(styles.disabled, !allowVideo)}>
      <div className={styles.hint}>
        <div className={styles.hintText}>
          <p>
            Mit dem Laden des Videos akzeptieren Sie die{" "}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
              Datenschutzerklärung
            </a>{" "}
            von YouTube.
          </p>
          <div className={styles.hintButtons}>
            <Button className={styles.button} onClick={() => setAllowVideo(true)}>
              Video laden
            </Button>
            <Button className={styles.button} onClick={() => alwaysAllow()}>
              Videos immer laden
            </Button>
          </div>
          <p>Die Erlaubnis besteht bis zum Schließen der Seite bzw. des Browsers.</p>
        </div>
        <img src={youtubePreviewUrl(embedId)} alt="" />
      </div>

      {allowVideo && (
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube-nocookie.com/embed/${embedId}?controls=2&rel=0&showinfo=0&playsinline=0&modestbranding=1&color=#0090aa`}
          frameBorder="0"
          allow={`accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture `}
          allowFullScreen
          title={`Embedded youtube `}
        />
      )}
    </div>
  )
}

export default Video
