import React, { forwardRef } from "react"
import styles from "./CatalogCategory.module.scss"
import { IMainCategory } from "../../../interfaces/IMainCategory"
import { useAppSelector } from "../../../state/store"
import { createServiceByCategorySelector } from "../../../state/selectors"
import CatalogService from "./CatalogService"
import ModuleTeaser from "../../Elements/Content/ModuleTeaser"

const CatalogCategory = forwardRef(function CatalogCategory(
  { category }: { category: IMainCategory },
  ref: React.ForwardedRef<HTMLElement>
) {
  const documents = useAppSelector(createServiceByCategorySelector(category))

  return (
    <section className={styles.category} ref={ref}>
      <ModuleTeaser category={category} />
      <ul className={styles.services}>
        {documents.map((service, index) => (
          <CatalogService key={index} service={service} />
        ))}
      </ul>
    </section>
  )
})

export default CatalogCategory
