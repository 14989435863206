import React from "react"
import styles from "./Modal.module.scss"

import ReactModal from "react-modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import Button from "./Button"

type ModalProps = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  classNames?: {
    overlay?: string
    content?: string
  }
}

function Modal({ open, onClose, children, classNames }: ModalProps) {
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      className={styles.content + (classNames?.content ? ` ${classNames?.content}` : "")}
      overlayClassName={`${styles.modal}${classNames?.overlay ? ` ${classNames?.overlay}` : ""}`}
    >
      <Button className={styles.close} onClick={onClose}>
        <FontAwesomeIcon icon={faXmark} />
      </Button>
      {children}
    </ReactModal>
  )
}

export default Modal
