import React, { forwardRef, useEffect } from "react"
import styles from "./Tile.module.scss"
import conditionalClassName from "../../../utility/conditionalClassName"

export enum TileType {
  DEFAULT = "default",
  NOTICE = "notice",
}

export interface ClassNames {
  tile?: string
  header?: string
  content?: string
  action?: string
}

export interface TileTab {
  label: string
  key: string
  content: React.ReactNode
}

interface TileProps extends Omit<React.HTMLAttributes<HTMLDivElement>, "className"> {
  className?: string | ClassNames
  header?: React.ReactNode
  headerBleed?: boolean
  children?: React.ReactNode
  action?: React.ReactNode
  type?: TileType
  tabs?: TileTab[]
  activeTab?: string
  onTabChange?: (activeTabKey: string) => void
  noTopMargin?: boolean
}

const Tile = forwardRef(function Tile(
  {
    className,
    children,
    header,
    headerBleed,
    action,
    type,
    tabs,
    activeTab,
    onTabChange,
    noTopMargin,
    ...props
  }: TileProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const [activeTabKey, setActiveTabKey] = React.useState<string>()
  const classNames = determineClassNames(className, tabs, action, headerBleed, type)

  const setTab = (activeTabKey: string) => {
    setActiveTabKey(activeTabKey)
    if (onTabChange) {
      onTabChange(activeTabKey)
    }
  }

  useEffect(() => {
    if (activeTab) {
      setActiveTabKey(activeTab)
    } else if (tabs && tabs.length > 0) {
      setActiveTabKey(tabs[0].key)
    }
    //eslint-disable-next-line
  }, [activeTab])

  return (
    <div
      className={classNames.tile + conditionalClassName(styles.noTopMargin, !!noTopMargin)}
      ref={ref}
      {...props}
    >
      {tabs ? <TabNavigation tabs={tabs} activeTabKey={activeTabKey} onClick={setTab} /> : null}
      {header ? <div className={classNames.header}>{header}</div> : null}
      <div className={classNames.content}>
        {tabs ? <TabsContent tabs={tabs} activeTabKey={activeTabKey} /> : children}
      </div>
      {action ? <div className={classNames.action}>{action}</div> : null}
    </div>
  )
})

export default Tile

function TabNavigation({
  tabs,
  activeTabKey,
  onClick,
}: {
  tabs: TileTab[]
  activeTabKey: string | undefined
  onClick: (activeTabKey: string) => void
}) {
  return (
    <nav className={styles.tabsNavigation}>
      {tabs.map((tab) => (
        <button
          key={tab.key}
          onClick={() => onClick(tab.key)}
          className={styles.tab + conditionalClassName(styles.active, tab.key === activeTabKey)}
        >
          {tab.label}
        </button>
      ))}
    </nav>
  )
}

function TabsContent({
  tabs,
  activeTabKey,
}: {
  tabs: TileTab[]
  activeTabKey: string | undefined
}) {
  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <section
          key={tab.key}
          className={
            styles.tabsContent + conditionalClassName(styles.active, tab.key === activeTabKey)
          }
        >
          {tab.content}
        </section>
      ))}
    </div>
  )
}

function determineClassNames(
  className: string | ClassNames = "",
  tabs: TileTab[] = [],
  actionContent: React.ReactNode = undefined,
  headerBleed: boolean = false,
  type: TileType = TileType.DEFAULT
) {
  let tile = styles.tile
  let header = styles.header
  let content = styles.content
  let action = styles.action

  if (typeof className === "string") {
    tile += ` ${className}`
  } else if (typeof className === "object") {
    if (className.tile) {
      tile += ` ${className.tile}`
    }
    if (className.header) {
      header += ` ${className.header}`
    }
    if (className.content) {
      content += ` ${className.content}`
    }
    if (className.action) {
      action += ` ${className.action}`
    }
  }

  if (tabs.length > 0) {
    tile += ` ${styles.hasTabs}`
  }

  if (headerBleed) {
    tile += ` ${styles.headerBleed}`
  }

  if (type) {
    tile += ` ${styles[type]}`
  }

  return { tile, header, content, action }
}
