import React from "react"
import styles from "./AnalysisSubCategory.module.scss"
import AnalysisChildren from "../AnalysisChildren"
import AnalysisStatementHeader from "../AnalysisStatementHeader/AnalysisStatementHeader"
import ICategoryBase from "../../../../interfaces/ICategoryBase"

type AnalysisSubCategoryProps = {
  category: ICategoryBase
}

function AnalysisSubCategory({ category }: AnalysisSubCategoryProps) {
  return (
    <section className={styles.subCategory}>
      <AnalysisStatementHeader title={category.title} />
      <AnalysisChildren categoryChildren={category.children} />
    </section>
  )
}

export default AnalysisSubCategory
