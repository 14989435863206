import React, { useEffect } from "react"
import styles from "./VideoTeaser.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCirclePlay } from "@fortawesome/free-regular-svg-icons"
import Button from "./Button"
import Modal from "./Modal"
import Video from "./Video"
import youtubePreviewUrl from "../../../utility/youtubePreviewUrl"

type ModalType = {
  embedId: string
  hideButton?: boolean
  open?: boolean
  onClose?: () => void
}

function VideoTeaser({ embedId, hideButton, open, onClose }: ModalType) {
  const [isOpen, setIsOpen] = React.useState(false)

  useEffect(() => {
    if (open) {
      setIsOpen(true)
    }
  }, [open])

  const openVideo = () => setIsOpen(true)

  return (
    <div className={styles.teaser + (hideButton ? " " + styles.hideButton : "")}>
      <button
        className={styles.image}
        style={{ backgroundImage: `url(${youtubePreviewUrl(embedId)})` }}
        onClick={openVideo}
      >
        <FontAwesomeIcon className={styles.icon} icon={faCirclePlay} />
      </button>
      {!hideButton && <Button onClick={openVideo}>Video zum KI-Einstieg anschauen</Button>}
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
          onClose && onClose()
        }}
        classNames={{ content: styles.modalContent }}
      >
        <Video embedId={embedId} />
      </Modal>
    </div>
  )
}

export default VideoTeaser
