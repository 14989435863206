import React, { useEffect } from "react"
import styles from "./RadioButton.module.scss"
import Button from "../Content/Button"
import { AnalysisAnswerState } from "../../../interfaces/IAnalysisAnswer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCrosshairs, faLocationDot } from "@fortawesome/free-solid-svg-icons"

type RadioButtonProps = {
  value: AnalysisAnswerState | null
  onChange: (value: AnalysisAnswerState) => void
}

function RadioButton({ value, onChange }: RadioButtonProps) {
  const [status, setStatus] = React.useState<AnalysisAnswerState | null>(value)

  useEffect(() => {
    setStatus(value)
  }, [value])

  let className = styles.switch

  if (status === AnalysisAnswerState.ACTUAL) {
    className += "  " + styles.actual
  } else if (status === AnalysisAnswerState.TARGET) className += " " + styles.target

  function change(value: AnalysisAnswerState) {
    setStatus(value)
    onChange(value)
  }

  return (
    <div className={className}>
      <Button className={styles.button} onClick={() => change(AnalysisAnswerState.ACTUAL)}>
        <FontAwesomeIcon icon={faLocationDot} /> <span>Ist</span>
      </Button>
      <Button className={styles.button} onClick={() => change(AnalysisAnswerState.TARGET)}>
        <FontAwesomeIcon icon={faCrosshairs} /> <span>Soll</span>
      </Button>
    </div>
  )
}

export default RadioButton
