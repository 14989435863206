import React from "react"
import styles from "./Introduction.module.scss"
import PageIntro from "../Elements/Content/PageIntro"
import ImageTeaser from "../Elements/Content/ImageTeaser"
import analysis from "../../assets/images/analysis.jpg"
import result from "../../assets/images/result.jpg"
import catalog from "../../assets/images/catalog.jpg"
import Button from "../Elements/Content/Button"
import StepNumber from "../Elements/Content/StepNumber"

function Introduction() {
  return (
    <div className={styles.page}>
      <PageIntro title="Einführung" action={<Button to={"/analyse"}>Weiter</Button>}>
        <p>
          Mit nur wenigen Klicks verstehen Sie KI im Technischen Service, definieren Ihre eigenen
          Anforderungen und finden einen niederschwelligen Einstieg! KI-Neulinge erfahren schnell
          und unkompliziert, wo ihre Stärken und Schwächen bei Serviceprozessen liegen.
        </p>
        <p>
          Mit unserem Service-Meister-Tool leiten wir Sie durch Ihre Prozessabläufe und finden
          gemeinsam das Verbesserungspotenzial einzelner Prozessschritte heraus. Ein detaillierter
          Report zeigt Ihre individuelle Servicelandschaft und verrät Ihnen in unserem
          Servicekatalog, welche erprobten KI-Anwendungen zu Ihnen passen.
        </p>
      </PageIntro>

      <ImageTeaser
        className={styles.teaser}
        image={analysis}
        alt=""
        action={<Button to={"/analyse"}>Weiter</Button>}
      >
        <h2>
          <StepNumber number={1} isActive={true} /> Analyse
        </h2>
        <p>Ermitteln Sie einfach den Optimierungsbedarf Ihres Services.</p>
      </ImageTeaser>

      <ImageTeaser className={styles.teaser} image={result} alt="">
        <h2>
          <StepNumber number={2} isActive={true} /> Report
        </h2>
        <p>Erhalten Sie einen umfassenden Einblick in Ihre Prozessschritte.</p>
        <p className="footnote">
          <a href="https://de.freepik.com/fotos-kostenlos/worker-ein-diagramm-zeichnet_928701.htm#query=statistik%20Gl%C3%BChbirne&position=45&from_view=search&track=ais">
            Bild von creativeart
          </a>{" "}
          auf Freepik
        </p>
      </ImageTeaser>

      <ImageTeaser
        className={styles.teaser}
        image={catalog}
        alt=""
        action={<Button to={"/katalog"}>Direkt zum Katalog</Button>}
      >
        <h2>
          <StepNumber number={3} isActive={true} /> Katalog
        </h2>
        <p>Entdecken Sie erprobte Optionen für Ihren Service.</p>
      </ImageTeaser>
    </div>
  )
}

export default Introduction
