import React from "react"
import { useAppSelector } from "../../../../state/store"
import { useNavigate } from "react-router-dom"
import { IMainCategory } from "../../../../interfaces/IMainCategory"
import ProcessNavigation from "../../../Elements/Navigation/ProcessNavigation"

function AnalysisNavigation() {
  const currentCategory = useAppSelector((state) => state.analysis.currentCategory)
  const survey = useAppSelector((state) => state.analysis.survey)
  const navigate = useNavigate()

  function goBack() {
    const index = getIndex(survey, currentCategory)
    if (survey && index > 0) {
      navigate(`/analyse/${survey[index - 1].slug}`)
    } else {
      navigate(`/analyse`)
    }
  }

  function goForward() {
    const index = getIndex(survey, currentCategory)
    if (survey && index < survey.length - 1) {
      navigate(`/analyse/${survey[index + 1].slug}`)
    } else {
      navigate(`/report`)
    }
  }

  return <ProcessNavigation onNext={goForward} onPrevious={goBack} />
}

export default AnalysisNavigation

function getIndex(statements: IMainCategory[] | null, currentCategory: IMainCategory | null) {
  return statements?.findIndex((category) => category.id === currentCategory?.id) ?? -1
}
