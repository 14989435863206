import React, { useEffect, useRef } from "react"
import ProcessNavigation from "../../Elements/Navigation/ProcessNavigation"
import PageIntro from "../../Elements/Content/PageIntro"
import Tile from "../../Elements/Content/Tile"
import { useAppDispatch, useAppSelector } from "../../../state/store"
import {
  currentServiceSelector,
  lastTabKeySelector,
  reportsSelector,
  surveySelector,
} from "../../../state/selectors"
import CatalogCategory from "./CatalogCategory"
import { IMainCategory } from "../../../interfaces/IMainCategory"
import { ReportResult } from "../../../interfaces/ISurveyReport"
import style from "./Catalog.module.scss"
import { setCurrentService, setLastTabKey } from "../../../state/catalogSlice"

const recommend = [ReportResult.LOW, ReportResult.MEDIUM]

function Catalog() {
  const survey = useAppSelector(surveySelector)
  const reports = useAppSelector(reportsSelector)
  const currentService = useAppSelector(currentServiceSelector)
  const lastTabKey = useAppSelector(lastTabKeySelector)
  const dispatch = useAppDispatch()
  const navigation = <ProcessNavigation toPrevious={"/report"} />
  const [activeTab, setActiveTab] = React.useState<string>()
  const [recommendations, setRecommendations] = React.useState<number[]>([])
  const moduleRefs = useRef(new Map<string, HTMLElement | null>())

  const listAll = createCategoryList("all", moduleRefs, survey)
  const listRecommended = createCategoryList("recommended", moduleRefs, survey, recommendations)

  useEffect(() => {
    const moduleNumbers = survey
      .map((c) => reports[c.id])
      .filter((r) => (r ? recommend.includes(r.evaluation.result) : false))
      .map((r) => r.category.module)

    setRecommendations(moduleNumbers)

    const tab = moduleNumbers.length === 0 ? "all" : "recommended"

    if (lastTabKey === null) {
      dispatch(setLastTabKey(tab))
    }

    setActiveTab(lastTabKey ?? tab)

    //eslint-disable-next-line
  }, [survey, reports])

  useEffect(() => {
    if (activeTab && currentService && moduleRefs.current.size > 0) {
      const category = survey.find((c) => c.module === currentService.module)
      if (category) {
        const ref = moduleRefs.current.get(lastTabKey + "_" + category.id)
        if (ref) {
          setTimeout(() => {
            ref.scrollIntoView()
            dispatch(setCurrentService(null))
          }, 100)
        }
      }
    }
    //eslint-disable-next-line
  }, [activeTab, recommendations])

  const onTabChange = (activeTabKey: string) => {
    dispatch(setLastTabKey(activeTabKey))
    setActiveTab(activeTabKey)
  }

  return (
    <>
      <PageIntro title="Katalog" action={navigation}>
        <p>
          Entdecken Sie im Servicekatalog erprobte Optionen für Ihren Service. Mit Ihrer
          Selbsteinschätzung erhalten Sie in der Rubrik „empfohlene Module“ eine Vorauswahl der
          jeweiligen Prozessschritte mit entsprechenden Anwendungsbeispielen zur KI-Anwendung im
          Technischen Service aus Service-Meister. Unter „Liste aller Module“ finden Sie alle Use
          Cases des Projekts.
        </p>
      </PageIntro>
      <Tile
        tabs={[
          {
            label: "Empfohlene Module",
            key: "recommended",
            content: listRecommended,
          },
          {
            label: "Liste aller Module",
            key: "all",
            content: listAll,
          },
        ]}
        activeTab={activeTab}
        onTabChange={onTabChange}
      ></Tile>
    </>
  )
}

function createCategoryList(
  type: string,
  moduleRefs: React.MutableRefObject<Map<string, HTMLElement | null>>,
  survey: IMainCategory[],
  filter: number[] = [1, 2, 3, 4, 5, 6]
) {
  const list = survey
    .filter((c) => filter.includes(c.module))
    .map((category) => (
      <CatalogCategory
        key={category.id}
        category={category}
        ref={(el) => moduleRefs.current.set(type + "_" + category.id, el)}
      />
    ))

  return filter.length === 0 ? (
    <p className={style.notice}>
      Damit wir Ihnen Module empfehlen können, müssen Sie die Analyse durchgeführt haben.
    </p>
  ) : (
    list
  )
}

export default Catalog
