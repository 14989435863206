import React, { useEffect } from "react"
import styles from "./AnalysisTabs.module.scss"
import { matchPath, NavLink, useLocation } from "react-router-dom"
import { useAppSelector } from "../../../../state/store"
import AnalysisProgressBar from "./AnalysisProgressBar"
import { progressSelector, surveySelector } from "../../../../state/selectors"
import conditionalClassName from "../../../../utility/conditionalClassName"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars"
import { faClose } from "@fortawesome/free-solid-svg-icons/faClose"

function AnalysisTabs() {
  const survey = useAppSelector(surveySelector)
  const progress = useAppSelector(progressSelector)
  const location = useLocation()
  const [open, setOpen] = React.useState(false)

  useEffect(() => setOpen(false), [location])

  const tabClassName: (param: { isActive: boolean }) => string = ({ isActive }) =>
    styles.tab + conditionalClassName(styles.active, isActive)

  const currentCategory = survey.find(
    (category) => !!matchPath(`/analyse/${category.slug}`, location.pathname)
  )

  return (
    <nav className={styles.wrapper}>
      <AnalysisProgressBar className={styles.totalProgress} />
      <button
        className={styles.title + conditionalClassName(styles.open, open)}
        onClick={() => setOpen(!open)}
      >
        <span>
          {currentCategory
            ? `${currentCategory.module}. ${
                currentCategory.navigationTitle ?? currentCategory?.title
              }`
            : ""}
        </span>
        <FontAwesomeIcon icon={open ? faClose : faBars} />
      </button>
      <ol className={styles.tabs + conditionalClassName(styles.open, open)}>
        {survey.map((category, index) => {
          const label = `${index + 1}. ${category.navigationTitle ?? category.title}`
          const path = `/analyse/${category.slug}`
          const percent = progress.byCategory[category.id] ?? 0
          return (
            <li
              key={index}
              style={{ ["--progress" as string]: `${percent}%` }}
              className={!!matchPath(path, location.pathname) ? styles.active : ""}
            >
              <AnalysisProgressBar className={styles.progress} category={category} />
              <NavLink className={tabClassName} title={label} to={path}>
                {label}
                <span className={styles.inlineProgress}>{percent}%</span>
              </NavLink>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default AnalysisTabs
