import { createSlice } from "@reduxjs/toolkit"

interface IState {
  youtubeVideo: boolean
}

const initialState: IState = {
  youtubeVideo: false,
}

export const consentSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {
    allowYouTubeVideo: (state) => ({
      ...state,
      youtubeVideo: true,
    }),
  },
})

export const { allowYouTubeVideo } = consentSlice.actions
export default consentSlice.reducer
