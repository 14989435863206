import React from "react"
import { IAnalysisStatement } from "../../../../interfaces/IAnalysisStatement"
import { AnalysisAnswerState } from "../../../../interfaces/IAnalysisAnswer"

import styles from "./AnalysisStatement.module.scss"
import { updateAnswer } from "../../../../state/analysisSlice"
import { useAppDispatch, useAppSelector } from "../../../../state/store"
import RangeSlider from "../../../Elements/Forms/RangeSlider"
import RadioButton from "../../../Elements/Forms/RadioButton"
import { answersSelector } from "../../../../state/selectors"

type AnalysisStatementProps = {
  statement: IAnalysisStatement
}

interface IGiveAnswerProps {
  priority?: number
  state?: AnalysisAnswerState
}

function AnalysisStatement({ statement }: AnalysisStatementProps) {
  const answers = useAppSelector(answersSelector)
  const dispatch = useAppDispatch()
  const currentAnswer = answers.find((a) => a.statement.id === statement.id)

  function giveAnswer({ priority, state }: IGiveAnswerProps) {
    dispatch(
      updateAnswer({
        statement,
        ...(priority !== undefined ? { priority } : {}),
        ...(state !== undefined ? { state } : {}),
      })
    )
  }

  return (
    <div className={styles.analysisStatement}>
      <span className={styles.label}>{statement.title}</span>
      <RadioButton
        value={currentAnswer?.state ?? AnalysisAnswerState.UNKNOWN}
        onChange={(state) => giveAnswer({ state })}
      />
      <span className={styles.sliderLabel}>Wichtigkeit</span>
      <RangeSlider
        min={0}
        max={3}
        step={1}
        defaultValue={currentAnswer?.priority ?? 0}
        className={styles.slider}
        onChange={(event) => giveAnswer({ priority: event[0] })}
      />
    </div>
  )
}

export default AnalysisStatement
