import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import ICatalogService from "../interfaces/ICatalogService"
import { slugify } from "../components/Pages/Analysis/slugify"

interface IState {
  currentService: ICatalogService | null
  services: ICatalogService[]
  enteredOnDetailView: boolean | null
  lastTabKey: string | null
}

const initialState: IState = {
  currentService: null,
  services: [],
  enteredOnDetailView: null,
  lastTabKey: null,
}

export const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    initCatalog: (state, action: PayloadAction<ICatalogService[]>) => {
      const collator = new Intl.Collator("de")
      const services = [...action.payload]
        .sort((a, b) => collator.compare(a.title, b.title))
        .map((service) => ({
          ...service,
          slug: slugify(service.title),
        }))

      return {
        ...state,
        services,
      }
    },
    setCurrentService: (state, action: PayloadAction<ICatalogService | null>) => {
      let enteredOnDetailView = state.enteredOnDetailView

      // as we set the currentService to "null" on entering the catalog, we can determine if a user has entered
      // the service detail view directly and set the flag accordingly
      if (enteredOnDetailView === null) {
        enteredOnDetailView = action.payload !== null
      } else if (enteredOnDetailView === true && action.payload === null) {
        enteredOnDetailView = false
      }

      return {
        ...state,
        currentService: action.payload,
        enteredOnDetailView,
      }
    },
    setLastTabKey: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        lastTabKey: action.payload,
      }
    },
  },
})

export const { initCatalog, setCurrentService, setLastTabKey } = catalogSlice.actions
export default catalogSlice.reducer
