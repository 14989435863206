import React, { useEffect } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { NavLink, useLocation } from "react-router-dom"

import styles from "./AppMobileMenu.module.scss"
import conditionalClassName from "../../../utility/conditionalClassName"

function AppMobileMenu({ className }: { className?: string }) {
  const [open, setOpen] = React.useState(false)
  const navClassName = styles.nav + conditionalClassName(styles.open, open)
  const { pathname } = useLocation()

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  const itemClassName: (props: { isActive: boolean }) => string = ({ isActive }) =>
    styles.item + conditionalClassName(styles.active, isActive)

  return (
    <div className={styles.menu + conditionalClassName(className)}>
      <button onClick={() => setOpen(!open)}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      <nav className={navClassName}>
        <ul>
          <li>
            <NavLink className={itemClassName} to="/">
              Zur Einführung
            </NavLink>
          </li>
          <li>
            <NavLink className={itemClassName} to="/analyse">
              Analyse
            </NavLink>
          </li>
          <li>
            <NavLink className={itemClassName} to="/report">
              Report
            </NavLink>
          </li>
          <li>
            <NavLink className={itemClassName} to="/katalog">
              Katalog
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default AppMobileMenu
