import React from "react"
import styles from "./CatalogService.module.scss"
import ICatalogService from "../../../interfaces/ICatalogService"
import Button from "../../Elements/Content/Button"
import { useNavigate } from "react-router-dom"

function CatalogService({ service }: { service: ICatalogService }) {
  const navigate = useNavigate()

  return (
    <li className={styles.catalogService} id={service.slug}>
      <div>
        <h4>{service.title}</h4>
        <p>
          {service.contacts
            .map((contact) => contact.company ?? contact.name ?? undefined)
            .filter((value, index, array) => array.indexOf(value) === index)
            .join(" / ")}
        </p>
      </div>
      <div>
        <Button onClick={() => navigate(`/katalog/${service.slug}`)}>Details</Button>
      </div>
    </li>
  )
}

export default CatalogService
