import React, { useState } from "react"
import { Outlet, useOutletContext } from "react-router-dom"
import { IAnalysisAnswer } from "../../../interfaces/IAnalysisAnswer"

function Analysis() {
  const [answers, setAnswers] = useState<IAnalysisAnswer[]>([])

  return (
    <>
      <Outlet context={{ answers, setAnswers }} />
    </>
  )
}

export default Analysis

export function useAnswers() {
  return useOutletContext<{
    answers: IAnalysisAnswer[]
    setAnswers: React.Dispatch<React.SetStateAction<IAnalysisAnswer[]>>
  }>()
}
