import React, { useEffect, useLayoutEffect, useRef } from "react"
import styles from "./ServiceCatalogDetail.module.scss"
import { useAppDispatch, useAppSelector } from "../../../state/store"
import { setCurrentService } from "../../../state/catalogSlice"
import { Navigate, useParams } from "react-router-dom"
import {
  catalogServiceBySlugSelector,
  reportByCategoryModuleSelector,
} from "../../../state/selectors"
import { ServiceSections } from "../../../interfaces/ICatalogService"
import Tile from "../../Elements/Content/Tile"
import ServiceSectionContent from "./ServiceSectionContent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import ReportFlower from "../../Elements/Content/ReportFlower"
import { ReportResult } from "../../../interfaces/ISurveyReport"
import Button from "../../Elements/Content/Button"
import TableOfContents from "./TableOfContents"

const tableOfContents = new Map<keyof ServiceSections, string>([
  ["challenges", "Herausforderungen"],
  ["use-case", "Anwendungsfall"],
  ["solution", "Lösung im Detail"],
  ["status-in-project", "Status im Projekt"],
  ["requirements", "Voraussetzungen"],
  ["availability", "Verfügbarkeit"],
  ["specification", "Spezifikation"],
])

function ServiceCatalogDetail() {
  const { slug } = useParams<{ slug: string }>()
  const service = useAppSelector(catalogServiceBySlugSelector(slug ?? ""))
  const report = useAppSelector(reportByCategoryModuleSelector(service?.module ?? 0))
  const dispatch = useAppDispatch()

  const tableOfContentEntries = Array.from(tableOfContents.entries())
  const tocRef: React.RefObject<HTMLDivElement> = useRef(null)
  const headerRef: React.RefObject<HTMLDivElement> = useRef(null)

  useEffect(() => {
    if (!service) return
    dispatch(setCurrentService(service))
    // eslint-disable-next-line
  }, [service])

  const articleRef: React.RefObject<HTMLElement> = useRef(null)

  useLayoutEffect(() => {
    if (tocRef.current && headerRef.current) {
      // setInterval(() => {
      setTimeout(() => {
        const bbox = tocRef?.current?.getBoundingClientRect()
        headerRef.current?.style.setProperty("--desktop-height", `${bbox?.height}px`)
        articleRef.current?.style.removeProperty("visibility")
      }, 100)
    }
  })

  if (!service) {
    return <Navigate to={"/katalog"} />
  }

  return (
    <article className={styles.detail} style={{ visibility: "hidden" }} ref={articleRef}>
      <header className={styles.header} ref={headerRef}>
        <Tile
          className={{
            tile: styles.titleTile,
            content: styles.titleTileContent,
          }}
          header={<Button to={"/katalog"}>Zurück</Button>}
        >
          <ReportFlower
            className={styles.flower}
            module={service.module}
            result={report?.evaluation?.result ?? ReportResult.MISSING_DATA}
          />

          <p className={styles.category}>
            KI-Service zu Modul {service.module} im Service-Lifecycle: {service.category}
          </p>
          <h2 className={styles.title}>{service.title}</h2>
        </Tile>
      </header>
      <nav className={styles.tableOfContents}>
        <TableOfContents entries={tableOfContentEntries} ref={tocRef} />
      </nav>
      <Tile className={styles.content}>
        <aside className={styles.contactList}>
          <h4>Ansprechpartner*innen</h4>
          <ul>
            {service.contacts.map((contact, index) => (
              <li key={index} className={styles.contact}>
                {contact.name ? <span className={styles.name}>{contact.name}</span> : null}
                {contact.company ? <span className={styles.company}>{contact.company}</span> : null}
                {contact.email ? (
                  <a className={styles.email} href={`mailto:${contact.email}`}>
                    <FontAwesomeIcon icon={faEnvelope} /> {contact.email}
                  </a>
                ) : null}
              </li>
            ))}
          </ul>
        </aside>
        {service.sections.abstract
          ? service.sections.abstract.map((c, k) => (
              <div className={styles.abstract} key={k}>
                <ServiceSectionContent section={c} />
              </div>
            ))
          : null}
        {service.sections.introduction
          ? service.sections.introduction.map((c, k) => (
              <ServiceSectionContent section={c} key={k} />
            ))
          : null}

        {tableOfContentEntries.map(([key, title]) => {
          const section = service.sections[key] ?? []
          return (
            <div key={key} className={styles.section + " " + styles[key]}>
              <h3>
                <a id={key} href={`#${key}`}>
                  {title}
                </a>
              </h3>
              {section.map((content, index) => (
                <div key={index} className={styles.sectionContent}>
                  <ServiceSectionContent section={content} />
                </div>
              ))}
            </div>
          )
        })}
      </Tile>
    </article>
  )
}

export default ServiceCatalogDetail
