import React, { useEffect, useState } from "react"
import styles from "./AnalysisMainCategory.module.scss"
import { useNavigate, useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../state/store"
import { IMainCategory } from "../../../../interfaces/IMainCategory"
import AnalysisChildren from "../AnalysisChildren"
import { setCurrentCategory } from "../../../../state/analysisSlice"
import AnalysisNavigation from "./AnalysisNavigation"
import Tile from "../../../Elements/Content/Tile"
import AnalysisStatementHeader from "../AnalysisStatementHeader/AnalysisStatementHeader"
import ReportChartDebugger from "../../Report/ReportChartDebugger"

function AnalysisMainCategory() {
  const survey = useAppSelector((state) => state.analysis.survey)
  const { slug } = useParams<{ slug: string }>()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [mainCategory, setMainCategory] = useState<IMainCategory>()
  const [number, setNumber] = useState<number>(0)

  useEffect(() => {
    const index = survey?.findIndex((category) => category.slug === slug)
    const mainCategory = survey?.[index ?? 0]

    if (mainCategory) {
      setNumber(index !== -1 ? index + 1 : 0)
      setMainCategory(mainCategory)
      dispatch(setCurrentCategory(mainCategory))
    } else {
      navigate("/analyse")
    }
    //eslint-disable-next-line
  }, [survey, slug])

  return mainCategory ? (
    <>
      <Tile
        className={{ tile: styles.mainCategory, content: styles.content }}
        action={<AnalysisNavigation />}
        headerBleed={true}
        header={
          <h1>
            {number}. {mainCategory.title}
          </h1>
        }
      >
        {mainCategory.children[0].type === "statement" ? <AnalysisStatementHeader /> : null}
        <AnalysisChildren categoryChildren={mainCategory.children} />
      </Tile>
      <ReportChartDebugger category={mainCategory} />
    </>
  ) : (
    <div>??</div>
  )
}

export default AnalysisMainCategory
