import React from "react"
import styles from "./ModuleTeaser.module.scss"
import Tile from "./Tile"
import VideoTeaser from "./VideoTeaser"
import Button from "./Button"
import { IMainCategory } from "../../../interfaces/IMainCategory"
import ReportFlower from "./ReportFlower"
import { ReportResult } from "../../../interfaces/ISurveyReport"
import { useAppSelector } from "../../../state/store"
import { reportByCategoryModuleSelector } from "../../../state/selectors"

type ImageTeaserProps = {
  category: IMainCategory
}

function ModuleTeaser({ category }: ImageTeaserProps) {
  const report = useAppSelector(reportByCategoryModuleSelector(category.module))
  const [isOpen, setIsOpen] = React.useState(false)

  const header = (
    <>
      <VideoTeaser
        embedId={category.youtubeId}
        hideButton={true}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
  const action = <Button onClick={() => setIsOpen(true)}>Video zu Modul anschauen</Button>

  return (
    <Tile
      className={{
        tile: styles.moduleTeaser,
        header: styles.header,
        content: styles.content,
        action: styles.action,
      }}
      header={header}
      headerBleed={true}
      action={action}
    >
      <header className={styles.title}>
        <div className={styles.flower}>
          <ReportFlower
            module={category.module}
            result={report?.evaluation?.result ?? ReportResult.MISSING_DATA}
          />
        </div>
        <div>
          <h4>Modul {category.module}</h4>
          <h3>{category.title}</h3>
        </div>
      </header>
      <p>{category.description}</p>
    </Tile>
  )
}

export default ModuleTeaser
