import React, { useEffect } from "react"
import "./ProcessMapSvg.scss"
import styles from "./ReportChart.module.scss"
import { ReactComponent as ProcessMapSvg } from "../../../assets/images/process-map.svg"
import ISurveyReport, { ReportResult } from "../../../interfaces/ISurveyReport"

function ReportChart({ reports }: { reports: { [key: string]: ISurveyReport } }) {
  useEffect(() => {
    for (const report of Object.values(reports)) {
      const tile = document.querySelector(`.pm-tiles__${report.category.slug}`)
      let tileClassName = "pm-tiles--color-"

      if (tile) {
        tile.classList.forEach((cn) => {
          if (cn.startsWith(tileClassName)) {
            tile.classList.remove(cn)
          }
        })

        setTimeout(() => {
          switch (report.evaluation.result) {
            case ReportResult.MISSING_DATA:
              tileClassName += "missing-data"
              break
            case ReportResult.LOW:
              tileClassName += "low"
              break
            case ReportResult.MEDIUM:
              tileClassName += "medium"
              break
            case ReportResult.HIGH:
              tileClassName += "high"
              break
          }

          tile.classList.add(tileClassName)
        }, 875)
      }
    }
  }, [reports])

  return (
    <div className={styles.chart}>
      <ProcessMapSvg />
    </div>
  )
}

export default ReportChart
