import React from "react"
import styles from "./ReportChartDebugger.module.scss"
import Tile from "../../Elements/Content/Tile"
import { useAppDispatch, useAppSelector } from "../../../state/store"
import { answersSelector, reportsSelector, surveySelector } from "../../../state/selectors"
import AnalyzerService from "../../../services/AnalyzerService"
import { ReportResultIcons, ReportResultLabels } from "../../../interfaces/ISurveyReport"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../../Elements/Content/Button"
import { resetAnswers, updateAnswer } from "../../../state/analysisSlice"
import { AnalysisAnswerState } from "../../../interfaces/IAnalysisAnswer"
import { IMainCategory } from "../../../interfaces/IMainCategory"

function ReportChartDebugger({ category }: { category?: IMainCategory }) {
  const dispatch = useAppDispatch()
  const survey = useAppSelector(surveySelector)
  const answers = useAppSelector(answersSelector)
  const reports = useAppSelector(reportsSelector)
  const categories = category ? [category] : survey

  function createRandomAnswers() {
    dispatch(resetAnswers())
    survey
      .flatMap((category) => AnalyzerService.findAllStatements(category))
      .map((statement) => ({
        statement: statement,
        priority: Math.floor(Math.random() * 4),
        state: Math.random() > 0.5 ? AnalysisAnswerState.ACTUAL : AnalysisAnswerState.TARGET,
      }))
      .every((answer) => dispatch(updateAnswer(answer)))
  }

  return process.env.REACT_APP_DEBUG ? (
    <Tile
      className={styles.reportDebugger}
      header={
        <h3>
          Debugging Auswertung
          {category ? ` für ${category.navigationTitle ?? category.title}` : ""}
        </h3>
      }
    >
      <table>
        <thead>
          <tr>
            <th>Kategorie</th>
            <th>Basis</th>
            <th>Ist</th>
            <th>Soll</th>
            <th>Punkte</th>
            <th></th>
            <th>Auswertung</th>
            <th>Schwellenwerte</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => {
            const report = reports[category.id] ?? AnalyzerService.createReport(category, answers)
            return (
              <tr key={index}>
                <td>{category.navigationTitle ?? category.title}</td>
                <td>{report.maxScore ? report.maxScore : "k.A."}</td>
                <td>{report.total.actual ?? "k.A."}</td>
                <td>
                  {report.total.target !== null && report.total.target >= 0
                    ? report.total.target * -1
                    : "k.A."}
                </td>
                <td>
                  <strong>
                    {" "}
                    {report.score !== null && report.maxScore !== null
                      ? report.maxScore + report.score
                      : "k.A."}{" "}
                  </strong>
                  / {report.maxScore ? report.maxScore * 2 : "k.A."}
                </td>
                <td className={styles.reportColor + " " + styles[report.evaluation.result]}>
                  <FontAwesomeIcon icon={ReportResultIcons[report.evaluation.result]} />
                </td>
                <td className={styles.reportColor + " " + styles[report.evaluation.result]}>
                  {ReportResultLabels[report.evaluation.result]}
                </td>
                <td style={{ fontSize: "0.8rem" }}>
                  {Array.from(AnalyzerService.thresholdDistribution.keys()).map((result, index) => {
                    const step = report.evaluation.steps[index]
                    const next = report.evaluation.steps[index + 1] ?? (report.maxScore ?? 0) * 2
                    return (
                      <div key={index} className={styles.reportColor + " " + styles[result]}>
                        <FontAwesomeIcon icon={ReportResultIcons[result]} />
                        <span>
                          {" "}
                          {step} bis {next}{" "}
                        </span>
                        <span>
                          (&gt;=
                          {(AnalyzerService.thresholdDistribution.get(result) ?? 0) * 100}
                          %)
                        </span>
                      </div>
                    )
                  })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <Button onClick={() => createRandomAnswers()}>Zufällige Antworten generieren</Button>
      <Button style={{ backgroundColor: "red" }} onClick={() => dispatch(resetAnswers())}>
        Alle Antworten zurücksetzen
      </Button>
      <dl>
        <dt>Basis</dt>
        <dd>Summe Wichtigkeit * Gewicht aller Antworten deren Wichtigkeit &gt; 0 ist</dd>
        <dt>Punkte</dt>
        <dd>Basis + Ist + Soll</dd>
        <dt>Maximale Punktzahl</dt>
        <dd>Basis * 2</dd>
      </dl>
    </Tile>
  ) : (
    <></>
  )
}

export default ReportChartDebugger
