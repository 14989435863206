import { IMainCategory } from "./IMainCategory"
import {
  faQuestion,
  faRocket,
  faScrewdriverWrench,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

export enum ReportResult {
  MISSING_DATA = "missing-data",
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export const ReportResultOptions = [
  ReportResult.MISSING_DATA,
  ReportResult.LOW,
  ReportResult.MEDIUM,
  ReportResult.HIGH,
]

export const ReportResultLabels: { [key: string]: string } = {
  [ReportResult.MISSING_DATA]: "Es fehlen Daten",
  [ReportResult.LOW]: "Ungenutztes Potenzial",
  [ReportResult.MEDIUM]: "Potenzial weiter ausschöpfen",
  [ReportResult.HIGH]: "Bereits gut aufgestellt",
}

export const ReportResultIcons: { [key: string]: IconDefinition } = {
  [ReportResult.MISSING_DATA]: faQuestion,
  [ReportResult.LOW]: faScrewdriverWrench,
  [ReportResult.MEDIUM]: faRocket,
  [ReportResult.HIGH]: faTrophy,
}

export default interface ISurveyReport {
  category: IMainCategory
  total: {
    actual: number | null
    target: number | null
  }
  score: number | null
  maxScore: number | null
  evaluation: {
    steps: number[]
    result: ReportResult
  }
}
