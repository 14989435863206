import React from "react"
import styles from "./Button.module.scss"
import { NavLink } from "react-router-dom"
import conditionalClassName from "../../../utility/conditionalClassName"

type ButtonProps = {
  children: React.ReactNode
  className?: string
  ghost?: boolean
  small?: boolean
  to?: string
  href?: string
  icon?: any
  [x: string]: any
}

function Button({ children, className, ghost, small, to, href, ...props }: ButtonProps) {
  className = styles.btn + conditionalClassName(className)
  className = className + conditionalClassName(styles.ghost, !!ghost)
  className = className + conditionalClassName(styles.small, !!small)

  if (href) {
    return (
      <a href={href} className={className} {...props}>
        {children}
      </a>
    )
  }

  if (to) {
    return (
      <NavLink to={to} className={className} {...props}>
        {children}
      </NavLink>
    )
  }

  return (
    <button className={className} {...props}>
      {children}
    </button>
  )
}

export default Button
