import React from "react"
import styles from "./AnalysisStart.module.scss"
import PageIntro from "../../Elements/Content/PageIntro"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../state/store"
import Button from "../../Elements/Content/Button"

function AnalysisStart() {
  const navigate = useNavigate()
  const survey = useAppSelector((state) => state.analysis.survey)
  const startUrl = `/analyse/${survey[0]?.slug ?? ""}`

  return (
    <div className={styles.start}>
      <PageIntro title="Analyse">
        <p>
          Lernen Sie über die Analyse Ihre Services besser kennen. Schätzen Sie die Prozessabläufe
          von der Servicemeldung und Ticketzuordnung über die Einsatzplanung und Bearbeitung vor Ort
          bis hin zum Servicebericht ein. Erstellen Sie in nur wenigen Schritten Ihren individuellen
          Servicereport. Mit ihm schärfen Sie den Blick für Anforderungen und Ziele einzelner
          Serviceprozesse und entdecken, wo Sie Potenzial weiter ausschöpfen können und noch
          ungenutzter Optimierungsbedarf vorhanden ist.
        </p>
      </PageIntro>
      <Button className={styles.button} onClick={() => navigate(startUrl)}>
        Analyse Beginnen
      </Button>
    </div>
  )
}

export default AnalysisStart
