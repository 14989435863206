import React from "react"
import styles from "./ReportChartLegend.module.scss"
import {
  ReportResultIcons,
  ReportResultLabels,
  ReportResultOptions,
} from "../../../interfaces/ISurveyReport"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function ReportChartLegend() {
  return (
    <ul className={styles["report-chart-legend"]}>
      {ReportResultOptions.map((reportResult, index) => (
        <li key={`rcl-${index}`} className={styles.item + " " + styles[`result-${reportResult}`]}>
          <span className={styles.icon}>
            <FontAwesomeIcon icon={ReportResultIcons[reportResult]} />
          </span>
          <span className={styles.label}>{ReportResultLabels[reportResult]}</span>
        </li>
      ))}
    </ul>
  )
}

export default ReportChartLegend
