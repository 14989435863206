import React from "react"
import Button from "../Content/Button"
import styles from "./ProcessNavigation.module.scss"

type ProcessNavigationProps = {
  onNext?: () => void
  onPrevious?: () => void
  toNext?: string
  toPrevious?: string
}

function ProcessNavigation({ onNext, onPrevious, toNext, toPrevious }: ProcessNavigationProps) {
  return (
    <nav className={styles.navigation}>
      {onPrevious ? (
        <Button className={styles.prev} onClick={onPrevious} to={toPrevious}>
          Zurück
        </Button>
      ) : null}
      {toPrevious ? (
        <Button className={styles.prev} to={toPrevious}>
          Zurück
        </Button>
      ) : null}
      {onNext ? (
        <Button className={styles.next} onClick={onNext} to={toNext}>
          Weiter
        </Button>
      ) : null}
      {toNext ? (
        <Button className={styles.next} to={toNext}>
          Weiter
        </Button>
      ) : null}
    </nav>
  )
}

export default ProcessNavigation
