import React from "react"
import AppStep from "./AppStep"

import styles from "./AppNavigation.module.scss"

function AppNavigation() {
  return (
    <nav className={styles.navigation}>
      <ol>
        <li>
          <AppStep number={1} label="Analyse" link="/analyse" />
        </li>
        <li>
          <AppStep number={2} label="Report" link="/report" />
        </li>
        <li>
          <AppStep number={3} label="Katalog" link="/katalog" />
        </li>
      </ol>
    </nav>
  )
}

export default AppNavigation
