import React from "react"
import { Link, useMatch } from "react-router-dom"

import styles from "./AppStep.module.scss"
import StepNumber from "../../Content/StepNumber"

type AppStepProps = {
  number: number
  label: string
  link: string
}

function AppStep({ number, label, link }: AppStepProps) {
  const match = useMatch(link + "/*")

  const className = match ? styles.step + " " + styles.active : styles.step

  return (
    <Link className={className} to={link}>
      <StepNumber number={number} isActive={Boolean(match)} />
      <span className={styles.text} title={label}>
        {label}
      </span>
    </Link>
  )
}

export default AppStep
