import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  AnalysisAnswerState,
  IAnalysisAnswer,
  IAnalysisAnswerDto,
} from "../interfaces/IAnalysisAnswer"
import { IMainCategory } from "../interfaces/IMainCategory"
import AnalyzerService from "../services/AnalyzerService"
import ISurveyReport from "../interfaces/ISurveyReport"

interface IState {
  currentCategory: IMainCategory | null
  answers: IAnalysisAnswer[]
  survey: IMainCategory[]
  progress: {
    total: number
    byCategory: {
      [key: string]: number
    }
  }
  reports: {
    [key: string]: ISurveyReport
  }
}

type ProgressByCategoryType = IState["progress"]["byCategory"]

const initialState: IState = {
  currentCategory: null,
  answers: [],
  survey: [],
  progress: {
    total: 0,
    byCategory: {},
  },
  reports: {},
}

export const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    initSurvey: (state, action: PayloadAction<IMainCategory[]>) => {
      return {
        ...state,
        survey: action.payload,
      }
    },
    setCurrentCategory: (state, action: PayloadAction<IMainCategory>) => {
      return {
        ...state,
        currentCategory: action.payload,
      }
    },
    updateAnswer: (state, action: PayloadAction<IAnalysisAnswerDto>) => {
      const answers = [...state.answers]
      const index = answers.findIndex(
        (answer) => answer.statement.id === action.payload.statement.id
      )

      // set answer
      if (index !== -1) {
        answers[index] = {
          ...answers[index],
          ...action.payload,
        }
      } else {
        answers.push({
          ...action.payload,
          state: action.payload.state ?? AnalysisAnswerState.UNKNOWN,
          priority: action.payload.priority ?? 0,
        } as IAnalysisAnswer)
      }

      // update progress
      const byCategory: ProgressByCategoryType = {}
      const reports: { [key: string]: ISurveyReport } = {}

      for (const category of state.survey) {
        byCategory[category.id] = AnalyzerService.calculateProgress(category, answers)
        reports[category.id] = AnalyzerService.createReport(category, answers)
      }

      const total =
        Object.values(byCategory).reduce((acc, curr) => acc + curr, 0) /
        Object.values(byCategory).length

      return {
        ...state,
        answers,
        progress: {
          total: Math.ceil(total),
          byCategory,
        },
        reports,
      }
    },
    resetAnswers: (state) => {
      return {
        ...state,
        answers: [],
        progress: {
          total: 0,
          byCategory: {},
        },
        reports: {},
      }
    },
    updateProgress: (state) => {
      const byCategory: ProgressByCategoryType = {}

      for (const category of state.survey) {
        byCategory[category.id] = AnalyzerService.calculateProgress(category, state.answers)
      }

      return {
        ...state,
        progress: {
          total: 0,
          byCategory,
        },
      }
    },
  },
})

export const { initSurvey, setCurrentCategory, updateAnswer, resetAnswers } = analysisSlice.actions
export default analysisSlice.reducer
