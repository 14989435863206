import React from "react"
import Tile from "./Tile"

type PageIntroProps = {
  title: string
  action?: React.ReactNode
  children?: React.ReactNode
}

function PageIntro({ title, action, children }: PageIntroProps) {
  return (
    <Tile header={<h1>{title}</h1>} action={action} noTopMargin>
      {children}
    </Tile>
  )
}

export default PageIntro
