import React, { useEffect } from "react"
import styles from "./Layout.module.scss"
import { Outlet, useLocation, useMatch } from "react-router-dom"
import AppHeader from "./Elements/Layout/AppHeader"
import AppNavigation from "./Elements/Layout/AppNavigation/AppNavigation"
import AnalysisHeader from "./Pages/Analysis/AnalysisHeader/AnalysisHeader"

function Layout() {
  const location = useLocation()
  const match = useMatch("/analyse/*")

  useEffect(() => {
    if (!location.hash) {
      // Scroll to top on page change and disable smooth scrolling
      window.scrollTo({ top: 0, behavior: "instant" as "smooth" })
    } else {
      document.querySelector(location.hash)?.scrollIntoView()
    }
  }, [location])

  return (
    <div className={styles.wrapper}>
      <AppHeader />
      <div className={styles.sticky}>
        <AppNavigation />
        {match && <AnalysisHeader />}
      </div>
      <main className={styles.content}>
        <Outlet />
      </main>
      <footer className={styles.footer}>
        <ul>
          <li>
            <a href="https://www.eco.de/impressum/">Impressum</a>
          </li>
          <li>
            <a href="https://www.eco.de/impressum/datenschutzerklaerung/">Datenschutzerklärung</a>
          </li>
        </ul>
      </footer>
    </div>
  )
}

export default Layout
