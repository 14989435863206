const youtubePreviewImagesMap = new Map([
	["6FjXGHyZtPY", require("../assets/images/youtube/6FjXGHyZtPY.jpg")],
	["WJ7fU9zccxY", require("../assets/images/youtube/WJ7fU9zccxY.jpg")],
	["xv25GR6WXJY", require("../assets/images/youtube/xv25GR6WXJY.jpg")],
	["ikNR8FylKDY", require("../assets/images/youtube/ikNR8FylKDY.jpg")],
	["DgRgrjdKHAs", require("../assets/images/youtube/DgRgrjdKHAs.jpg")],
	["WkF2meAkNNo", require("../assets/images/youtube/WkF2meAkNNo.jpg")],
	["N-WNHjihPpk", require("../assets/images/youtube/N-WNHjihPpk.jpg")],
]);

export default youtubePreviewImagesMap;