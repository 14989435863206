import React from "react"
import styles from "./AnalysisProgressBar.module.scss"
import { useMatch } from "react-router-dom"
import { IMainCategory } from "../../../../interfaces/IMainCategory"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { useAppSelector } from "../../../../state/store"
import { createProgressSelector } from "../../../../state/selectors"
import conditionalClassName from "../../../../utility/conditionalClassName"

type AnalysisProgressBarProps = {
  category?: IMainCategory
  className?: string
}

function AnalysisProgressBar({ category, className }: AnalysisProgressBarProps) {
  const percent = useAppSelector(createProgressSelector(category?.id))
  const match = useMatch("/analyse/" + category?.slug)
  className = styles.progress + conditionalClassName(className)

  if (percent > 60) {
    className += " " + styles["text-left"]
  }

  if (percent === 100) {
    className += " " + styles.done
  }

  if (category && match) {
    className += " " + styles.active
  }

  if (!category) {
    className += " " + styles.totalProgress
  }

  const style: React.CSSProperties = {
    ["--progress" as string]: `${percent}%`,
    ["--progress-scale" as string]: `${percent / 100}`,
  }

  return (
    <div className={className} style={style}>
      <div className={styles.progressBar} data-progress={`${percent}%`} />
      <FontAwesomeIcon icon={faCheck} className={styles.icon} />
    </div>
  )
}

export default AnalysisProgressBar
